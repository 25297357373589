import React from "react";
import "./Sponsors.css";
import sponsorContent from "../../../content/sponsorContent.json";

const Sponsors = () => {
  const importImage = (imageName) => {
    return `${process.env.PUBLIC_URL}/photos/logos/${imageName}`;
  };

  return (
    <div className="sponsor-modal">
      <h2 className="sponsor-modal-title">Tak til alle vores sponsorer</h2>
      <div className="sponsor-modal-content">
        {sponsorContent.map((content, index) => (
          <div key={index} className="sponsor-modal-content-item">
            <a
              href={content.link}
              target="_blank"
              rel="noreferrer"
              className="sponsor-link-text"
            >
              {content.name}
            </a>
            <a href={content.link} target="_blank" rel="noreferrer">
              <img
                className="logo"
                src={importImage(content.image)}
                alt={content.name}
              />
            </a>
            <p className="sponsor-phone-text">{content.phone}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
