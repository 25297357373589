import React, { useState } from "react";
import "./Page.css";
import Card from "../Card";
import eventsData from "../../content/koncertContent.json";

const Page = () => {
  const [filter, setFilter] = useState("upcoming");
  const [eventTypeFilter, setEventTypeFilter] = useState("all");
  const now = new Date();

  const parseEventDate = (event) => {
    const inputDate = event.date.slice(-8); // Format: DD.MM.YY
    const [day, month, year] = inputDate.split(".").map(Number);
    const fullYear = year < 100 ? 2000 + year : year;
    return new Date(fullYear, month - 1, day);
  };

  const filteredEvents = eventsData
    .map((event) => ({
      ...event,
      parsedDate: parseEventDate(event),
    }))
    .filter((event) => {
      const isUpcoming = event.parsedDate >= now;
      const isPast = event.parsedDate < now;

      let timeMatch = true;
      switch (filter) {
        case "past":
          timeMatch = isPast;
          break;
        case "upcoming":
        default:
          timeMatch = isUpcoming;
          break;
      }

      const typeMatch =
        eventTypeFilter === "all" ||
        (event.type && event.type === eventTypeFilter);
      return timeMatch && typeMatch;
    })
    .sort((a, b) => {
      const isAUpcoming = a.parsedDate >= now;
      const isBUpcoming = b.parsedDate >= now;

      switch (filter) {
        case "upcoming":
          return a.parsedDate - b.parsedDate; // Soonest first
        case "past":
          return b.parsedDate - a.parsedDate; // Most recent past first
        case "all":
        default:
          // Sort upcoming first, soonest first
          if (isAUpcoming && !isBUpcoming) return -1;
          if (!isAUpcoming && isBUpcoming) return 1;

          // Both upcoming → soonest first, or both past → most recent first
          return isAUpcoming
            ? a.parsedDate - b.parsedDate
            : b.parsedDate - a.parsedDate;
      }
    });

  return (
    <>
      <div className="filter-buttons">
        <div className="filter-group">
          {["upcoming", "past"].map((val) => (
            <button
              key={val}
              onClick={() => setFilter(val)}
              className={`filter-button ${filter === val ? "active" : ""}`}
            >
              {val === "upcoming" && "Kommende"}
              {val === "past" && "Afholdte"}
            </button>
          ))}
        </div>

        <div className="filter-group">
          {[
            "all",
            "Musikfestival",
            "Torsdagskoncert",
            "Event",
            "Tirsdagsmarked",
          ].map((type) => {
            const baseClass = "filter-button";
            const activeClass = eventTypeFilter === type ? "active" : "";
            const colorClass = `filter-${type
              .toLowerCase()
              .replace(/\s+/g, "-")}`;

            return (
              <button
                key={type}
                onClick={() => setEventTypeFilter(type)}
                className={`${baseClass} ${colorClass} ${activeClass}`}
              >
                {type === "all" ? "Alle" : type}
              </button>
            );
          })}
        </div>
      </div>

      <div className="articles">
        {filteredEvents.map((event, index) => (
          <Card key={index} content={event} />
        ))}
      </div>
    </>
  );
};

export default Page;
