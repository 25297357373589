import { React, useState, useCallback } from "react";
import "./App.css";
import Overlay from "./components/Overlay";
import Modal from "./components/Modal/Modal";
import Poster from "./components/ModalContent/Poster";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Page from "./components/Page/Page";
import Sponsors from "./components/ModalContent/Sponsors/Sponsors";

function App() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  const toggleOverlay = useCallback((menuItem) => {
    setIsOverlayVisible((prev) => !prev);
    setSelectedMenuItem(menuItem || "");
  }, []);

  return (
    <div className="App">
      <Overlay isVisible={isOverlayVisible} toggleOverlay={toggleOverlay} />
      <Modal isVisible={isOverlayVisible} toggleOverlay={toggleOverlay}>
        {selectedMenuItem === "plakat" && <Poster />}
        {selectedMenuItem === "sponsor" && <Sponsors />}
      </Modal>
      <Header isVisible={isOverlayVisible} toggleOverlay={toggleOverlay} />
      <Page />
      <Footer />
    </div>
  );
}

export default App;
