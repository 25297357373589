import React from "react";
import "./Poster.css";

const Poster = () => {
  const importImage = (imageName) => {
    return `${process.env.PUBLIC_URL}/photos/poster/${imageName}`;
  };
  return (
    <div className="modal">
      <h2 className="modal-title">Festivalplakat 2025</h2>
      <div className="modal-content">
        <img
          className="poster-img"
          src={importImage("plakat2025.webp")}
          alt="Festivalplakat 2025"
        />
        {/* <div className="modal-content-column">
          <img
            className="poster-img"
            src={importImage("plakat25.webp")}
            alt="Festivalplakat 2025"
          />
        </div> */}

        {/* <div className="modal-content-column">
          <span className="artist-label">Kunstneren bag årets plakat</span>
          <div className="artist-info">
            <p className="artist-name">X</p>
            <img
              className="artist-img"
              src={importImage("artist.webp")}
              alt="Portræt af Kunster"
            />
            <div className="artist-bio">
              <span>Om kunstneren</span>
              <p className="quote">Kommer snart.</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Poster;
