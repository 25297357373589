import React from "react";
import "./Header.css";
import logo from "../../photos/sandviglogo.webp";

const Header = ({ isVisible, toggleOverlay }) => {
  return (
    <header className="top-nav">
      <a
        href="https://www.sandvig.info"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="header-logo"
          src={logo}
          alt="Sandvig logo"
          loading="lazy"
        />
      </a>
      <input id="menu-toggle" type="checkbox" />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu">
        <li onClick={() => toggleOverlay("plakat")}>Festivalplakat 2025</li>
        <li onClick={() => toggleOverlay("sponsor")}>Sponsorer</li>
      </ul>
    </header>
  );
};

export default Header;
